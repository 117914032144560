import React from 'react'
import jsonp from "jsonp";
import * as EmailValidator from 'email-validator';
import styled from '@emotion/styled'

const translate = {
  name: 'Nom',
  firstname: 'Prénom',
  email: 'Email',
};

/** Styles */
const Form = styled.form`
  
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  
  font-size: 16px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-bottom: 2rem!important
  }
  label, input, select {
    display: block;
    margin: 0;
    width: 100%;
  }

  label {
    padding: 0 0 5px 5px;
    font-weight: 600;
    small {
      display: block;
    }
  }
  input {
    padding-left: 10px;
  }
  input, select {
    height: 40px;
    outline: none;
  }
  
  select option {
    background: #dddddd;
  }
`;

const FormBlock = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  
  align-items: center;
  justify-content: space-between;

  > div {
    width: 50%;
    max-width: 520px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 95%;
      margin: 0 auto;
      padding: 1rem 2rem!important;
      h3 {
        font-size: 18px!important
      }
    }
    &:first-of-type {
      padding-right: 20px;
    }
    &:last-of-type {
      padding-left: 20px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding-left: 0;
      }
    }
    > div {
      padding: 10px 0;
      &.subWrapper {
        display: flex;
        justify-content: space-between;
        > div {
          width: 45%;
        }
      }
    }
  }
`;


class NewsletterForm extends React.Component {
  state = {
    name: null,
    firstname: null,
    email: null,
    errors: [],
    success: false,
    loading: false,
  }

  componentDidMount() {
    let search = '';
    if (this.props.location) {
      search = this.props.location.search
    }

    if (search !== '') {
      if (/^\?_e=/.test(search)) {
        const email = search.split('?_e=')[1];
        this.setState({
          ...this.state,
          email: email
        })
      }
    }
  }

  submitForm = () => {
    let errors = [];

    if (!this.state.name) {
      errors.push('name');
    }

    if (!this.state.firstname) {
      errors.push('firstname');
    }

    if (!this.state.email || !EmailValidator.validate(this.state.email)) {
      errors.push('email');
    }

    if (errors.length === 0) {
      const _self = this;
      this.setState({ loading: true })

      const { name, firstname, email } = this.state;
      const { uid, aid, fid, endpoint } = this.props;

      const params = {
        EMAIL: email,
        FNAME: firstname,
        LNAME: name,
        u: uid,
        id: aid,
        f_id: fid,
      };

      const urlParam = Object.keys(params).map(item => `${item}=${params[item]}`).join('&');
      const url = `https://${endpoint}/subscribe/post-json?${urlParam}`;

      const button = document.getElementById('btn');
      const nameInput = document.getElementById('name');
      const firstnameInput = document.getElementById('firstname');
      const emailInput = document.getElementById('email');

      button.setAttribute('disabled', '');
      nameInput.setAttribute('disabled', '');
      firstnameInput.setAttribute('disabled', '');
      emailInput.setAttribute('disabled', '');

      jsonp(url, {
        param: "c"
      }, function (err, data) {
        if (err) {
          console.log(err)
        } else if (data.result !== "success") {
          console.log(err)
        } else {
          if (data.result === 'success') {
            _self.setState({ success: true, loading: false })
          }
        }
      });
    } else {
      this.setState({ errors: errors })
    }
  }

  render() {

    const { lang, title, successMessage, gdpr, style } = this.props;

    return (
      <Form>
        <FormBlock style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{
            border: `2px solid ${style ? style.layoutColor : '#b4cfb0'}`,
            borderRadius: '10px',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}>
            <h3 style={{
              marginBottom: '.5rem',
              marginTop: '-1.8rem',
              textAlign: 'center',
              background: style ? style.titleBackground : '#e7e7e7',
            }}>
              {title}
            </h3>
            <div style={{
              marginBottom: '.5rem'
            }}>
              {
                this.state.errors.length > 0 && (
                  <React.Fragment>
                    {
                      lang === 'fr' ? (
                        'Les champs suivants sont requis : '
                      ) : (
                        'These fields are required: '
                      )
                    }
                    {` `}
                    {
                      this.state.errors.map(item => translate[item]).join(', ')
                    }
                  </React.Fragment>
                )
              }
            </div>

            {
              this.state.success ? (
                <div style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  display: 'block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}>
                  {
                    successMessage ? successMessage : (
                      <React.Fragment>
                        Votre inscription a bien été prise en compte,<br />merci pour votre engagement !
                      </React.Fragment>
                    )
                  }
                </div>
              ) : (
                <React.Fragment>
                  <div>
                    <label>
                      { lang === 'fr' ? 'Prénom*' : 'First name*' }
                    </label>
                    <input 
                      style={{border: `1px solid ${this.state.errors.indexOf('firstname') >= 0 ? '#ff0000' : '#ddd'}`}} 
                      type="text" 
                      name="firstname" 
                      id="firstname" 
                      placeholder={lang === 'fr' ? 'Saisissez votre prénom' : 'Type your first name'} 
                      defaultValue={this.state.firstname}
                      onChange={(e) => this.setState({ 
                        ...this.state,
                        firstname: e.target.value, 
                        errors: this.state.errors.filter(i => i !== 'firstname') 
                      })}
                    />
                  </div>
                  <div>
                    <label>
                      { lang === 'fr' ? 'Nom*' : 'Last name*' }
                    </label>
                    <input 
                      style={{border: `1px solid ${this.state.errors.indexOf('name') >= 0 ? '#ff0000' : '#ddd'}`}} 
                      type="text" 
                      name="name" 
                      id="name" 
                      placeholder={lang === 'fr' ? 'Saisissez votre nom' : 'Type your last name'} 
                      defaultValue={this.state.name}
                      onChange={(e) => this.setState({ 
                        ...this.state,
                        name: e.target.value, 
                        errors: this.state.errors.filter(i => i !== 'name') 
                      })}
                    />
                  </div>
                  <div>
                    <label>
                      Email*
                      {
                        this.state.errors.indexOf('email') >= 0 && (
                          <small>Votre adresse email semble invalide, veuillez saisir une adresse email valide.</small>
                        )
                      }
                    </label>
                    <input 
                      style={{border: `1px solid ${this.state.errors.indexOf('email') >= 0 ? '#ff0000' : '#ddd'}`}} 
                      type="email" 
                      name="email" 
                      id="email" 
                      placeholder={lang === 'fr' ? 'Saisissez votre adresse email' : 'Type your email address'} 
                      defaultValue={this.state.email}
                      onChange={(e) => this.setState({ 
                        ...this.state,
                        email: e.target.value, 
                        errors: this.state.errors.filter(i => i !== 'email') 
                      })}
                    />
                  </div>
                  
                  {
                    gdpr && (
                      <p style={{
                        fontSize: '16px'
                      }}>
                        En cliquant sur le bouton "Valider" je consens à recevoir les publications de dischr.com 
                        et j'accepte <a target="_blank" href="https://www.dischr.com/conditions-utilisation-des-donnees-personnelles">la politique d'utilisation des données du site</a>
                      </p>
                    )
                  }

                  <button 
                    id="btn"
                    onClick={(e) => { e.preventDefault(); this.submitForm(); }}
                    style={{
                      border: `1px solid ${style ? style.layoutColor : '#b4cfb0'}`,
                      background: style ? style.layoutColor : '#b4cfb0',
                      color: '#f2f2f2',
                      fontWeight: 'bold',
                      display: 'block',
                      padding: '.5rem 1rem',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      opacity: this.state.loading ? .5 : 1,
                      outline: 'none',
                      margin: '1rem auto 0',
                      width: '100%'
                    }}
                  >
                    {
                      this.state.loading ? (
                        <React.Fragment>
                          {
                            lang === 'fr' ? 'Enregistrement en cours...' : 'Pending...'
                          }
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {
                            lang === 'fr' ? 'Valider' : 'Submit'
                          }
                        </React.Fragment>
                      )
                    }
                  </button>
                </React.Fragment>
              )
            }
          </div>
        </FormBlock>
      </Form>
    )
  }
}

export default NewsletterForm;