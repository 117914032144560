import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image";
import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper } from '../components'
import website from '../../config/website'
import NewsletterForm from '../components/Newsletter';

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 1.35rem;
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

const translate = {
  name: 'Nom',
  firstname: 'Prénom',
  email: 'Email',
}

class LaLettre extends React.Component {

  render() {

    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet title={`Disch Rémy - La lettre`}>
          <script defer data-domain="dischr.com" src="https://plausible.io/js/script.js"></script>
        </Helmet>
        
        <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Inner>
            <Head>
              <Img
                fixed={data.banner.childImageSharp.fixed}
                placeholderStyle={{
                  filter: "grayscale(100%) contrast(100%)",
                  imageRendering: "pixelated",
                  opacity: 0,
                }}
              />
              <Hero style={{ position: 'absolute', bottom: 0, left: '1rem' }}>
                <div style={{ width: '100%' }}>
                  <h3 style={{ color: '#fff', opacity: .8 }}>
                    <strong>Abonnements</strong><br />
                    <small>La lettre</small><br />
                  </h3>
                </div>
              </Hero>
            </Head>
            <Hero>
              <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                <h3 style={{ marginTop: '2rem' }}>Historique</h3>
                <p>
                  La lettre, initiée par Rémy il y a quelques années dans l'esprit des correspondances d'artistes du début du siècle dernier, est un endroit privilégié.{` `}
                  Cette interface vous permet, en tant que collectionneur-se-s, en plus d'avoir une actualité au plus proche de lui, d'accéder aux catalogues aux prix atelier.
                </p>
                <p>
                    Les prix atelier représentent 35% de réduction en moyenne par rapport à la cote officielle de Rémy et aux prix pratiqués par ses différents partenaires internationaux.
                </p>
                <p>
                  Enfin, cette correspondance permet à Rémy de garder un contact avec ces plus fidèles collectionneurs-se-s et de poursuivre son activité et ses recherches, ce qui n'est pas tout à fait un détail vous en conviendrez.
                </p>

                <h3 style={{ marginTop: '5rem' }}>Art et boursouflure technocratique du XXIe siècle</h3>
                <p>
                  Comme vous le savez peut être, internet est un supermarché dont vous êtes le principal produit. Vos données personnelles sont soumises au RGPD (Règlement général sur la protection des données) afin, entre autres, d'en légitimer la vente...
                  <br /><a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees" target="_blank">https://www.cnil.fr/fr/reglement-europeen-protection-donnees</a>
                </p>
                <p>
                  Dans le cas du site dischr.com, nous n'enregistrons pas vos données personnelles.
                  <br />Nous utilisons vos adresses email récoltées en vous rencontrant physiquement pour vous tenir informé-e-s.
                  <br />Un accord tacite entre nous qui, dans une configuration moins absurde, ne poserait de problème à personne.
                </p>
                <p>
                  Dans le cadre d'un envoi massif d'emails, parce que la liste des collectionneurs s'aggrandi et que les frontières se repoussent, les données auxquelles nous avons accès (vos emails) sont jugées "non consenties" car "hors système".
                </p>
                <p>
                  Nous sommes donc limités par ce règlement et potentiellement considérés au mieux comme du spam, comme cela a été le cas lors de mon dernier envoi du dimanche 20 novembre, et au pire hors la loi.
                </p>
                <p>
                  Afin de remédier à ce problème et de maintenir ce pont essentiel entre Rémy et vous, nous devons donc nous adapter. Ce n'est pas grand chose, il suffit pour vous, si vous le souhaitez, de confirmer votre engagement à recevoir La lettre et bénéficier de ses avantages en remplissant le petit formulaire juste en dessous.
                </p>
                <p>
                  (Si vous êtes allergiques aux formulaires, n'hésitez pas à m'envoyer ces informations par email à <a className="regular-link" href="mailto:davidbraud@dischr.com">davidbraud@dischr.com</a>, je me chargerai de confirmer l'inscription manuellement)
                </p>
                <p>
                  Nous enregistrons vos noms et prénoms pour les associer à vos adresses email et nous enregistrons vos consentements aux <a className="regular-link" target="_blank" href="https://www.dischr.com/conditions-utilisation-des-donnees-personnelles">conditions d'utilisation des données personnelle du site</a>.
                </p>
                <p>
                  Cela aura pour effet de protéger la réputation du site, de collectivement nous protéger juridiquement et de légitimer les prochaines campagnes d'emailing que je vous enverrai.
                </p>
                <p>
                  Veuillez m'excuser pour l'absence de poésie dans ce message, cela me semble important pour Rémy et pour vous.
                </p>

                <p>
                  Merci de m'avoir accordé ce temps de lecture !
                  <br />À très bientôt,
                  <br /><br />David Braud
                </p>
              </div>
            </Hero>
          </Inner>
        </IndexWrapper>

        <IndexWrapper id={website.skipNavId} style={{ 
          background: '#e7e7e7',
          padding: 0,
          width: '100%',
          maxWidth: '100%',
        }}>
          <Inner>
            <Hero>
              <div style={{ width: '100%', paddingTop: '2rem', paddingLeft: 0, paddingRight: 0 }}>
                <NewsletterForm
                  location={this.props.location}
                  uid="eb648200f481a06084c140afe"
                  aid="776a998ef7"
                  fid="007eb1e0f0"
                  endpoint="pm.us14.list-manage.com"
                  title="Je souhaite recevoir La lettre"
                  lang="fr"
                  gdpr
                />
              </div>
            </Hero>
          </Inner>
        </IndexWrapper>

        <IndexWrapper id={website.skipNavId}>
          <Inner>
            <Hero>
              <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
                <h3 style={{ marginTop: '5rem' }}>La lettre du 20 novembre 2022</h3>
                <p>
                  <a className="regular-link" href="https://www.dischr.com/2022-11-19/" target="_blank">La seconde naissance</a>
                </p>

                <h3 style={{ marginTop: '5rem' }}>Nouvelles oeuvres</h3>
                <p>
                  Vous pouvez consulter les dernières oeuvres à cette adresse :
                  <br /><a className="regular-link" href="https://www.dischr.com/works/2022/" target="_blank">https://www.dischr.com/works/latest/</a>.
                </p>

                <h3 style={{ marginTop: '5rem' }}>Les catalogues</h3>
                <p>
                  Vous pouvez accéder au <strong>catalogue des oeuvres 2021-2022</strong> à cette adresse : <br />
                  {` `}<a className="regular-link" href={`https://www.dischr.com/catalog`} target="_blank">https://www.dischr.com/catalog</a>{` `} <br /><br />
                  et au <strong>catalogue fonds d'atelier</strong> à cette adresse : <br />
                  {` `}<a className="regular-link" href={`https://www.dischr.com/catalog/fonds-d-atelier`} target="_blank">https://www.dischr.com/catalog/fonds-d-atelier</a>{` `}
                </p>

                <h3 style={{ marginTop: '5rem' }}>Acquérir des oeuvres</h3>
                <p>
                  Si vous souhaitez acquérir et/ou vous informer de la disponibilité des oeuvres, 
                  je vous invite à me contacter directement en utilisant l'adresse email et le numéro de téléphone en pied de page. 

                </p>
                <p>
                  Vous pouvez également retrouver des oeuvres sur le site de notre partenaire <a className="regular-link" href="https://www.artsper.com/es/artistes-contemporains/france/102408/remy-disch" target="_blank">Artsper</a>
                </p>

                <p>
                  <span style={{ paddingTop: '3rem', display: 'block' }}>
                    ---
                    <br />(+33) 651 453 881
                    <br /><a className="regular-link" href="mailto: davidbraud@dischr.com">davidbraud@dischr.com</a>
                    <br /><a className="regular-link" href={`https://www.dischr.com`} target="_blank">dischr.com</a>
                  </span>
                </p>
              </div>
            </Hero>
          </Inner>
        </IndexWrapper>
      </React.Fragment>
    );
  }
}

export default LaLettre;

export const pageQuery = graphql`
  query LaLettre {
    banner: file(name: {eq: "bandeau_mail4"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
